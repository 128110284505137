<template>
  <div class="evaluate">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      v-if="!noData"
    >
      <div
        class="evaluateUl"
        v-for="(PARENT, index) in evaluateList"
        :key="index"
      >
        <div class="evaluateLi" v-for="item in PARENT" :key="item.id">
          <div class="info">
            <van-image
              round
              width="1rem"
              height="1rem"
              :src="item.formUidAvatar"
              v-if="item.formUidAvatar"
            />
            <img
              src="../../assets/img/noImg.png"
              alt=""
              style="width: 1rem;border-radius: 50%;"
              v-else
            />
            <p>
              <span
                ><font>{{ item.formUidName }}</font
                ><font>{{ item.createTime }}</font></span
              >
              <van-rate v-model="item.star" size="15" readonly color="orange" />
            </p>
          </div>
          <div class="content">{{ item.content }}</div>
          <div class="attendant">
            <p>
              <van-image
                round
                width="0.5rem"
                height="0.5rem"
                :src="item.artificerAvatar"
                v-if="item.artificerAvatar"
                class="headerImg"
              />
              <img
                src="../../assets/img/noImg.png"
                alt=""
                style="width: 0.5rem;border-radius: 50%;"
                v-else
                class="headerImg"
              />
              <!-- <font>{{ item.artificerName }}</font> -->
              <font>{{ item.nickName }}</font>
            </p>
            <span class="delete" @click="deleteEvaluate(item)">删除</span>
          </div>
        </div>
      </div>
    </van-list>
    <div class="nullDataDiv" v-else>
      <img src="../../assets/img/error.png" alt="" />
      <span class="text">暂无数据</span>
    </div>
  </div>
</template>

<script>
import { pageMyComment, deleteMyComment } from "@/api/my";
export default {
  data() {
    return {
      noData: true,
      loading: false,
      finished: false,
      page: 1,
      total: 0,
      evaluateList: []
    };
  },
  created() {
    this.pageMyComment();
  },
  methods: {
    // 删除评论
    deleteEvaluate(item) {
      deleteMyComment({ id: item.id }).then(res => {
        if (res.data) {
          this.$toast("删除成功！");
          this.pageMyComment();
        }
      });
    },
    // 获取评论列表
    pageMyComment() {
      let data = {
        pageIndex: this.page,
        pageSize: 10
      };
      pageMyComment(data).then(res => {
        this.loading = false;
        this.total = res.data.total;
        if (res.data.records.length > 0) {
          this.evaluateList[this.page - 1] = res.data.records;
        } else {
          this.finished = true;
        }
        if (this.evaluateList.length > 0) {
          this.noData = false;
        }
      });
    },
    // 分页
    onLoad() {
      if (this.page < Math.floor((this.total + 9) / 10)) {
        this.page++;
        this.pageMyComment();
      } else {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.evaluate {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgb(244, 244, 244);
  .evaluateUl {
    padding: 0.4rem 0.4rem 0;
    .evaluateLi {
      margin-bottom: 0.4rem;
      background: white;
      border-radius: 0.3rem;
      padding: 0.4rem;
      .info {
        display: flex;
        align-items: center;
        p {
          margin-left: 0.2rem;
          flex: 1;
          display: flex;
          flex-direction: column;
          span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.1rem;
            font:first-child {
              font-weight: bold;
              font-size: 0.36rem;
            }
            font:last-child {
              color: grey;
            }
          }
        }
      }
      .content {
        margin: 0.2rem 0;
      }
      .attendant {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          display: flex;
          align-items: center;
          background: rgb(237, 236, 236);
          padding: 2px 6px;
          border-radius: 20px;
          .headerImg {
            margin-right: 3px;
          }
        }
        .delete {
          color: red;
        }
      }
    }
  }
}
</style>
